import React from 'react';
import MoodSlider from '../components/MoodSlider';
import ToyList from '../components/ToyList';
import image from '../assets/1.jpg'; // Import your 1.jpg image
import imageDom from '../assets/dom.jpg';

function Home() {

  return (
    <div>
      <MoodSlider 
        title="Dom/Sub" 
        min="Dom"
        max="Sub"
      />
      <MoodSlider 
        title="Top/Bottom" 
        min="Top"
        max="Bottom" 
      />

      <ToyList /> 
    </div>
  );
}

export default Home;

import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import toys from '../data/toys';

export default function ToyDropdown({ selectedToy, onToySelect }) {
  return (
    <Autocomplete
      value={selectedToy}
      onChange={(event, newValue) => {
        onToySelect(newValue);
      }}
      options={toys}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select a toy"
          variant="outlined"
        />
      )}
    />
  );
}


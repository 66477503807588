import { Slider, Typography, Box } from '@mui/material';
import React, { useState } from 'react';

function MoodSlider({ title, min, max }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const marks = [
    { value: -5, label: min },  
    { value: -4, label: '4' },  
    { value: -3, label: '3' },  
    { value: -2, label: '2' },
    { value: -1, label: '1' },
    { value: 0, label: '0' },  
    { value: 1, label: '1' },  
    { value: 2, label: '2' },  
    { value: 3, label: '3' },  
    { value: 4, label: '4' }, 
    { value: 5, label: max }, 
  ];

  const valueLabelFormat = (value) => Math.abs(value); 

  return (
    <Box mb={2}>
      <Typography variant="h6" align="center">{title}</Typography>
      <Slider
        track={false}
        value={value}
        min={-5}
        max={5}
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
        sx={{ width: '100%' }}
        color='primary'
        marks={marks}
      />
    </Box>
  );
}

export default MoodSlider;

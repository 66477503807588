import React, { useState } from 'react';
import ToyDropdown from './ToyDropdown';
import { Button, Box, IconButton, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

function ToyList() { 
  const [selectedToys, setSelectedToys] = useState([null]); // Start with one null toy

  const handleToySelect = (index, toy) => {
    setSelectedToys(prevToys => prevToys.map((t, i) => (i === index ? toy : t)));
  };

  const addToyDropdown = () => {
    setSelectedToys(prevToys => [...prevToys, null]); 
  };

  const removeToyDropdown = (index) => {
    if (selectedToys.length > 1) { 
      setSelectedToys(prevToys => prevToys.filter((_, i) => i !== index));
    } else {
      setSelectedToys([null]); 
    }
  };

  const removeAllToys = () => {
    setSelectedToys([null]); // Reset to one empty dropdown
  };

  return (
    <div>
      <Box mt={2}>
        {selectedToys.map((toy, index) => (
          <Box key={index} mb={2}>
            <Grid container alignItems="center">
              <Grid item xs={10}> 
                <ToyDropdown
                  selectedToy={toy}
                  onToySelect={(newToy) => handleToySelect(index, newToy)}
                />
              </Grid>
              <Grid item xs={2}> 
                <IconButton onClick={() => removeToyDropdown(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>

      <Box mt={2} display="flex" justifyContent="center"> 
        <Button 
          variant="outlined" 
          startIcon={<AddIcon />}
          onClick={addToyDropdown}
          sx={{ marginRight: '16px' }} 
        >
          Add Another Toy
        </Button>

        <Button 
          variant="outlined" 
          startIcon={<DeleteIcon />}
          onClick={removeAllToys}
          color="error" 
        >
          Remove All
        </Button>
      </Box>

      <Box mt={2}>
        <h2>Selected Toys:</h2>
        <ul>
          {selectedToys
            .filter(toy => toy !== null) 
            .map((toy, index) => (
              <li key={index}>{toy.name}</li>
            ))}
        </ul>
      </Box>
    </div>
  );
}

export default ToyList;

import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './pages/Home';

const theme = createTheme({
  // Your custom theme configuration here (optional)
  palette: {
    mode: 'dark', // or 'dark'
    primary: {
      main: '#f44336',
    },
    secondary: {
      main: '#e91e63',
    },
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> 
      <Container maxWidth="md"> {/* "md" corresponds to 75% on large screens */}
        <Home />
      </Container>
    </ThemeProvider>
  );
}

export default App;

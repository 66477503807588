const toys = [
    { id: 1, name: "Bondage cuffs" },
    { id: 2, name: "Collar" },
    { id: 3, name: "Rope" },
    { id: 4, name: "Blindfolds" },
    { id: 5, name: "Butt plugs" },
    { id: 6, name: "Floggers" },
    { id: 7, name: "Riding crop" },
    { id: 8, name: "Nipple clamps" },
    { id: 9, name: "Whip" },
    { id: 10, name: "Vibrator" }
  ];  

export default toys;
